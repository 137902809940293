import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>絳堡坊數據科技-JumboFun</title>
                <meta name="description" content="絳堡坊數據科技-JumboFun AI數據分析 UI介面設計  網路科技 " />
                <meta name="og:title" property="og:title" content="絳堡坊數據科技-JumboFun AI數據分析 UI介面設計  網路科技"></meta>
                <meta name="twitter:card" content="絳堡坊數據科技-JumboFun AI數據分析 UI介面設計  網路科技"></meta>
                {/* <link rel="canonical" href="https://rewy-react.envytheme.com/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" /> */}
            </Helmet>
        </div>
    )
}

export default SEO
