import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import slogan from "../../assets/images/slogan.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <img src={slogan} alt="slogan" />

                            
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                        
                    </div>

                    

                    <div className="col-lg-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>聯絡方式</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    桃園市桃園區宏昌七街54號 
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+44587154756">0919873577</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:askme@jumbofun.fun">askme@jumbofun.fun</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Jumbofun</strong> All rights reserved </p>
                        </div>

                        
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;